import create from 'zustand';

export const useSectionsStore = create((set) => ({
  sections: [],
  setSections: (updatedSections) => set({ sections: updatedSections }),
  filterForms: (sections, dependencies) =>
    sections.map((section) => ({
      ...section,
      forms: filterFormByCondition(dependencies, section.forms),
    })),
}));

const filterFormByCondition = (dependen, form) => {     
  const filter = form.filter(obj => {
      if (obj.dependencies && obj.dependencies.length > 0) {
          return obj.dependencies.every(dependency => {
              const { dependencyKey, dependencyValues } = dependency;
              const selectedValue = dependen[dependencyKey];
              if (selectedValue) {
                  return Array.isArray(selectedValue)
                  ? selectedValue.some(value => dependencyValues.includes(value))
                  : dependencyValues.includes(selectedValue);
              } else {
                  return false;
              }
          });
      } else {
          return true;
      }
  });

  return filter;
};