import { urlsAws, urls } from "../../../../../resources/foo/api-endpoints";
import Auth from "@aws-amplify/auth";

type Body = { [key: string]: any } | FormData | null;
type Method = "GET" | "POST" | "PUT" | "DELETE";
type FetchData = {
  url: string;
  method?: Method;
  body?: Body;
  saveCache?: boolean;
  isFile?: boolean;
};

const fetchData = async ({
  url = "",
  method = "GET",
  body = null,
}: FetchData) => {
  try {
    if (!url) return false;
    const auth = await Auth.currentSession();
    const headers: { [key: string]: string } = {
      AccessToken: auth.getAccessToken().getJwtToken(),
      Authorization: auth.getIdToken().getJwtToken(),
    };
    let requestOptions: RequestInit = {
      method,
      headers,
    };

    if (body instanceof FormData) {
      requestOptions.body = body;
    } else {
      headers["Content-Type"] = "application/json; charset=utf-8";
      requestOptions.body = body ? JSON.stringify(body) : null;
    }

    const response = await fetch(url, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getTask = async (parameters: string) => {
  const data = await fetchData({
    url: urlsAws.bonus + '?' + parameters,
    method: "GET",
  });

  if (data.response && data.response.valid) {
    return data;
  } else throw new Error("getTask home ownership ", data); 
};

export const postTask = async (formData: FormData) => {
  const data = await fetchData({
    url: urlsAws.bonus,
    method: "POST",
    body: formData,
  });

  if (data.response && data.response.valid) {
    return data;
  } else throw new Error("postTask ", data);

};
