// modalStore.ts
import create from 'zustand';

interface DataModal {
    icon: string;
    msg: string;
    callBack: () => void;
    subtext: string;
}

interface ModalStore {
    dataModal: DataModal;
    setDataModal: (data: DataModal) => void;
}

export const useModalStore = create<ModalStore>((set) => ({
    dataModal: {
        icon: '',
        msg: '',
        callBack: () => null,
        subtext: ''
    },
    setDataModal: (data) => set({ dataModal: data }),
}));
