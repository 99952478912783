import { FC } from "react";
import { Controller } from "react-hook-form";
import { InputForm } from "../../RemoteJob/interfaces/interfaces";

const TextInput:FC<InputForm> = ({formState, input, control}) => {
    let date = new Date();
    const dateToday = date.toISOString().split("T")[0];
    return(
        <>
            <span className="data-list-box__text ms-2">
                <Controller
                    name={input.key}
                    control={control}
                    defaultValue={input.defaultValue || '' }
                    render={({ field }) => (
                    <>
                        <div className={input.type === "date" ? "row w-auto" : ""}>
                            <div className={input.type === "date" ? "col-10": ""}>
                                <input
                                type={input.type}
                                onChange={e => field.onChange(e)}
                                value={field.value}
                                className="form-control"
                                id={input.key}
                                placeholder={input.placeholder}
                                readOnly={input.readOnly}
                                max={input.type === 'date' ? dateToday : ""}
                                />
                            </div>
                            {input.type === 'date' && 
                                <div className="col-2">
                                    <i className="fa fa-calendar fa-fw mt-2 fa-lg color-primary-strong" aria-hidden="true"></i>
                                </div>
                            }
                        </div>
                    </>
                    )}
                />
                {formState.errors[input.key] && formState.errors[input.key]?.message ? (
                    <div className="invalid-feedback d-block">
                        {formState.errors[input.key]?.message}
                    </div>
                ) : null}
            </span>
        </>
    )
}

export default TextInput;