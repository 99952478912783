import { FilesSolicProps } from "../../RemoteJob/interfaces/interfaces";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { bytesToMB } from "../../../../services/utiles";

const FilesRemoteJob:FC<FilesSolicProps> = ({input, control, formState, setTotalFiles, setFileNames, fileNames, dependencies, unregister, isBonus, modalKey, totalFiles}) => {
    
    const handleFileChange = async (e: any, field: any, key: string) => {
        const files = e.target.files[0];
        let totalSize = files?.size;
        const fileName = files?.name;
        if(files){
            if (isBonus && modalKey) {
                setTotalFiles((prevDependencies) => {
                    const updatedFiles = { ...prevDependencies };
                    const uniqueKey = `${modalKey}_${key}`;
                    updatedFiles[uniqueKey] = totalSize;
                    return updatedFiles;
                });
                setFileNames && setFileNames((prevNames) => {
                    const updatedNames = { ...prevNames };
                    const uniqueKey = `${modalKey}_${key}`;
                    updatedNames[uniqueKey] = fileName;
                    return updatedNames;
                })
            } else {
                setTotalFiles(prevState => ({ ...prevState, [key]: totalSize }));
            }
            field.onChange(files);
        } else {
            if (isBonus && modalKey){
                setTotalFiles((prevDependencies) => {
                    const updatedFiles = { ...prevDependencies };
                    delete updatedFiles[`${modalKey}_${key}`];
                    return updatedFiles;
                });
                setFileNames && setFileNames ((prevNames) => {
                    const updatedNames = { ...prevNames };
                    delete updatedNames[`${modalKey}_${key}`];
                    return updatedNames;
                })
            } else {
                setTotalFiles((prevDependencies) => {
                    const updatedFiles = { ...prevDependencies };
                    delete updatedFiles[key];
                    return updatedFiles;
                });
            }
        }
    };

    // useEffect(()=> {
    //     if(isBonus && modalKey){
    //        if(dependencies?.relacion){
    //            console.log("si entroxxx ")
    //             setTotalFiles((prevDependencies) => {
    //                 const updatedFiles = { ...prevDependencies };
    //                 delete updatedFiles[`${modalKey}_${input.key}`];
    //                 return updatedFiles;
    //             });
    //             setFileNames && setFileNames ((prevNames) => {
    //                 const updatedNames = { ...prevNames };
    //                 delete updatedNames[`${modalKey}_${input.key}`];
    //                 return updatedNames;
    //             })
    //             unregister && unregister(input.key)
    //             removeKey(modalKey, input.key)
    //        }
    //     }
    //   },[dependencies, input.key, isBonus, modalKey])
    
    return(
        <>
            <div className="label-wrapper w-sm-70 ms-2">
                <div className="col">
                    <Controller
                        name={input.key}
                        control={control}
                        render={({ field }) => (
                            <>
                                <input
                                    id={input.key}
                                    name={field.name}
                                    type={input.type}
                                    onChange={(e) => {
                                        handleFileChange(e, field, input.key)
                                    }}
                                    ref={field.ref}
                                    accept={isBonus ? ".pdf": ".jpg, .jpeg, .png, .pdf"}
                                    className="form-control"
                                />
                            </>
                        )}
                    />
                    {totalFiles && totalFiles[input.key] && <p> Peso de archivo: {Number(totalFiles[input.key]) ? bytesToMB(Number(totalFiles[input.key])): 0} MB.</p>}
                    
                    {fileNames && modalKey && fileNames[`${modalKey}_${input.key}`] && 
                    <p>Nombre de archivo: {fileNames[`${modalKey}_${input.key}`].length > 25 ? fileNames[`${modalKey}_${input.key}`].slice(0,25) + '(...).' + fileNames[`${modalKey}_${input.key}`].split('.').pop() : fileNames[`${modalKey}_${input.key}`]}</p>}

                    {formState.errors[input.key] && formState.errors[input.key]?.message ? (
                        <div className="invalid-feedback d-block">
                            {formState.errors[input.key]?.message}
                        </div>
                    ) : null} 
                </div> 
            </div>
        </>
    )
}
export default FilesRemoteJob;