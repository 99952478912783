import { useQuery } from "react-query";
import { getBonus } from "../services/apiRequest";
import { ResponseBonus, typeBonus } from "../interfaces/interfaces";

export const useBonusGetQuery = (type: typeBonus) => {
    return useQuery<ResponseBonus>([`bonus-${type}`], () => getBonus(type), {
        retry: 2,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
    });
}