const base = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4001/api/v1';
const global = process.env.REACT_APP_BACKEND_URL_GLOBAL || 'http://localhost:4000';

export const urls = {
    modNoticia: base + "/noticias/modificar-noticias",
    getHistorialNoticia: base + "/noticias/get-historial-noticia",
    deleteNoticia: base + "/noticias/delete-noticia",
    undoNoticia: base + "/noticias/undo-noticia",
    getNoticiaById: base + "/noticias/get-noticia-mod",
    getAllNoticias: base + "/noticias/get-all-noticias",
    getAllgrupos: base + "/noticias/get-all-group",
    addLikeNoticia: base + "/add-like-noticia",
    substractLikeNoticia: base + "/substract-like-noticia",
    getExcelResume: base + "/noticias/get-excel-resume",

    getAppState: base + "/admin/get-app-state",
    getState: base + "/admin/get-state",
    setState: base + "/admin/set-state",
    syncPUB: base + "/admin/sync-pub",
    setRol: base + "/admin/set-rol",
    getRol: base + "/admin/get-rol",
    getPaises: base + "/admin/get-paises",
    getUBxPais: base + "/CN_DATANEGOCIO.v1/getList?country=",
    getUserIdentity: base + "/admin/get-user-identity",
    getUserRut: base + "/groupUserRut",
    groupUser: base + "/groupUser",
    login_dummy: base + "/login",
    getGroup: base + "/getGroup",
    getuser: base + "/getUser",
    newUser: base + "/newUser",
    newGroup: base + "/newGroup",
    getCertificadoCovid: base + "/get-certificado-covid",
    LoginSendRequest: base + "/employee",
    conInt: {
        get: base + "/employee",
        post: base + "/employee",
        list: "http://localhost:4001/CN_CONLIST.v1/language/ESP",
    },
    getCountryList: base + "/countries",
    sendNotify: "https://fcm.googleapis.com/fcm/send",
    regions: base + "/list",

    //Vacaciones
    getPDF: base + "/retrieve-pdf",

    //NOTICIASgetListadoNoticiaUniqueLocal
    newNoticia: base + "/newNoticia", // Crear noticia db
    getInfoNoticias: base + "/infoNoticias",
    getListadoNoticiaUniqueLocal: base + "/fullNoticiaUnique",
    sendComentario: base + "/noticias/addComentario",
    getComentarios: base + "/noticias/getComentarios",
    eliminarComentario: base + "/noticias/deleteComentario",
    getNoticiasDestacadas: base + "/noticias/get-noticias-destacadas",
    //inicio
    getLicenciasEquipo: base + "/getLicenciasEquipo/",
    getNews: base + "/news",
    // NewsLetter
    newsLetter: base + '/newsletter',
    regionalNewsLetter: global + '/api/newsletter',
    // Dirección del Trab
    gobDT: "https://www.dt.gob.cl/legislacion/1624/w3-article-119954.html",
    // Archivos
    file: base + "/file",
    listFiles: base + "/list-files",
};

export const urlsAws = {
    getBanderasUn: base + "/empleados/operaciones/CN_DATANEGOCIO.v1/getList?country=", //comentado en el service
    certificadosAntiguedad: base + "/get-certificado-antiguedad",
    datosLaborales: base + "/datos-laborales",
    firstLogin: base + "/first-login",
    PhotoSendRequest: base + "/photo-send-request",
    getDatosPersonales: base + "/datos-personales",
    sendRequestModal: base + "/send-request-modal",
    getCarpetaDigital: base + "/get-carpeta-digital",
    getArchivoCarpetaDigital: base + "/descargar-documento",
    getEvaluacionEmpleado: base + "/get-evaluacion-empleado",
    getFirmaDocumentos: base + "/get-firma-documento",
    setFirmar: base + "/set-firmar",
    setRechazar: base + "/set-rechazar",
    documento: base + "/get-documento",
    getEmail_att: base + "/get-email-att",
    //MI EQUIPO
    getMiEquipo: base + "/get-mi-equipo",
    getColaborador: base + "/get-colaborador",
    getFichaSic: base + "/get-ficha-sic",
    setFichaSic: base + "/set-ficha-sic",
    getRemuneracionLista: base + "/get-remuneracion-lista",
    getRemuneracionDetalle: base + "/get-remuneracion-detalle",
    getVariableList: base + "/get-variable-list",
    getBankList: base + "/get-bank-list",
    getDocumentList: base + "/get-document-list",
    //encuestas
    getEncuestas: base + "/get-encuestas",
    sendEncuestas: base + "/send-encuestas",
    validatePollUser: base + "/validate-poll-user",
    createPollUser: base + "/create-poll-user",

    getUniformes: base + "/get-uniformes",
    getComunasProvinciasRegiones: base + "/get-full-direcciones",
    getFicha: base + "/get-ficha",
    setFicha: base + "/set-ficha",
    welcomeNotification: base + "/welcome-notification",
    getLiquidaciones: base + "/get-liquidaciones",
    //Vacaciones
    getFeriados: base + "/get-feriados",
    getVacacionesSubAlterno: base + "/get-vacaciones-subalterno",
    anularVacaciones: base + "/anular-vacaciones",
    rechazarVacaciones: base + "/rechazar-vacaciones",
    aprobarVacaciones: base + "/aprobar-vacaciones",
    getDetalleDiasDisponibles: base + "/get-dias-disponibles",
    solicitarVacaciones: base + "/solicitar-vacaciones",
    getAusencias: base + "/get-ausencias",
    getDetalleVacaciones: base + "/get-detalle-vacaciones",
    papeletaVacaciones: base + "/papeleta-vacaciones",
    solicitarReasignacion: base + "/realizar-reasignacion",
    buscarReasignacion: base + "/buscar-reasignacion",
    eliminarReasignacion: base + "/eliminar-reasignacion",
    getPhotosEquipo: base + "/get-fotos",
    getCartola: base + "/get-cartola",
    getValidCollaborator: base + "/get-valid-collaborator",
    //moduloDt
    validateRUT: base + "/validar-rut",
    getBannerBeneficiosList: base + "/banner-beneficios-list",
    getListadoBeneficios: base + "/listado-beneficios",
    getBeneficioFull: base + "/beneficio-full",
    addLikeBeneficio: base + "/add-like",
    substractLikeBeneficio: base + "/substract-like",
    setBeneficioRevisada: base + "/beneficio-revisada",
    getBeneficioCantidadVistas: base + "/beneficio-cantidad-vista",
    auditoria: base + "/auditoria-dt",

    //inicio
    getFeaturedNews: base + "/home/featured-news",
    getProximosCumpleanos: base + "/home/upcoming-birthdays",
    getMiEquipoInicio: base + "/home/my-team",
    getBenficiosInicio: base + "/home/benefits",
    //mis solicitudes
    getAnticipo: base + "/get-anticipos",
    getBono: base + "/get-bonos",
    postAnticipo: base + "/post-anticipo",
    postBono: base + "/post-bono",

    //geovictoria
    getLibroAsistencia: base + "/get-libro-asistencia",

    //mitiempo
    getResumenTiempo: base + "/get-resumen-tiempo",
    getBeneficiosTiempo: base + "/get-beneficios-tiempo",
    solicitarBeneficio: base + "/solicitar-tiempo",
    getHistorial: base + "/get-historial-tiempo",
    getEquipo: base + "/get-equipo-tiempo",
    anularTiempo: base + "/anular-tiempo",
    aprobarTiempo: base + "/aprobar-tiempo",

    //conflictos
    getConflictosLista: base + "/get-conflictos-lista",
    getConflictos: base + "/get-conflictos",
    setConflictos: base + "/set-conflictos",

    setAnexoContrato: base + "/set-anexo-contrato",

    getPersonalVoucher: base + "/get-personal-voucher",

    //Dashboard
    getDashboard: base + "/get-dashboard",
    getDashboardHome: base + "/get-dashboard-home",
    getDashboardSection: base + "/get-dashboard-section",

    //Bono anual
    getAnnualBond: base + "/get-annual-bond",
    postAnnualBond: base + "/post-annual-bond",
    postDownloadDocumentAB: base + "/Download-Document-AB",
    getMyModel: base + "/get-my-model",
    getTeamModel: base + "/get-team-model",

    // Menú: ContentReference
    getMenu: global + "/api/acl/contentreference",

    // Validar Certificados
    verifyDocument: global + "/api/validate-certificates",
    getCertificado: base + "/get-certificado",

    // Warning Letter
    getWarningLetter: base + "/get-warning-letter",
    postWarningLetter: base + "/post-warning-letter",
    getWarningLetterFile: base + "/get-doc-warning-letter",

    //newsLetter
    getNewsLetter: base + "/newsletter",

    //tareas
    task: global + "/api/task",

    //New Benefits
    getBenefitList: global + "/api/benefit/list",
    getCategoryList: global + "/api/benefit/category",
    crudCategoryBenefit: global + "/api/benefit/category",
    crudBenefit: global + "/api/benefit",
    getParamsBenefit: global + "/api/benefit/parameters",
    getBannerBenefits: global + "/api/benefit/banner",
    getBannerBenefitsHome: global + "/api/benefit/banner-home",
    getBenefitCard: global + "/api/benefit/card",
    postLikeBenefit: global + "/api/benefit/like",
    getPhotoBenefit: global + "/api/benefit/photo",
    getExcelBenefits: global + "/api/benefit/excel-resume",
    getFileBenefit: global + "/api/benefit/file",
    //remoteJob
    remoteJob: base + "/remote-job",
    remoteJobAnswer: base + "/remote-job-answer",

    //donations
    donations: base + "/donation",
    
    //new bonuses
    bonus: base + "/bonus/home",


    ethics: base + "/ethics",
    revalidateEthics: base + "/ethics/revalidate",
    //task
    ethicsManager: base + "/ethics/manager",
    ethicsEmployee: base + "/ethics/employee",

    //home
    getNextBirthdays: global + "/home/nextBirthdays",
    getVacationsAndLicenses: global + "/home/vacationsAndLicenses",
    getShortcuts: global + "/home/getShortcuts",
    getVacations: global + "/home/vacations",

    //news
    //New Benefits
    getNewsList: global + "/api/newNews/list",
    getCategoryNewsList: global + "/api/newNews/category",
    crudCategoryNews: global + "/api/newNews/category",
    crudNews: global + "/api/newNews",
    crudCommentNews: global + "/api/newNews/comment",
    getParamsNews: global + "/api/newNews/parameters",
    getBannerNewsList: global + "/api/newNews/banner",
    getBannerNewsHome: global + "/api/newNews/banner-home",
    getNewsCard: global + "/api/newNews/card",
    postLikeNews: global + "/api/newNews/like",
    getPhotoNews: global + "/api/newNews/photo",
    getExcelNewsList: global + "/api/newNews/excel-resume",
    getFileNews: global + "/api/newNews/file",

    //documentTrust
    documentTrust: global + "/api/document-trust",
};
