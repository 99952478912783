import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { RemoteJob } from '../../RemoteJob/pages';
import BonusForm from '../components/BonusIndex';
import { typeBonus } from '../interfaces/interfaces';
import Bonos from '../../../bono';
import BreadcrumRequest from '../../Commons/Breadcrum/BreadcrumMyRequests';

const Bonuses = () => {
    const { tipo }: { tipo?: typeBonus } = useParams();
    const navigate = useNavigate();

    switch (tipo) {
        case "defuncion":
        case "matrimonio":
        case "nacimiento":
            return <Bonos />;

        // case "premiohabitacional":
        //     return (
        //             <>
        //                 <BreadcrumRequest title="Premio Habitacional" />
        //                 <BonusForm type={tipo} />
        //             </>
        //         );

        case "ley-de-conciliacion":
            return <RemoteJob />

        default:
            return (
                    <>
                        <div className="text-center mt-5">
                            <p>Ha ocurrido un error al obtener la información o el bono no existe. Por favor, intente más tarde.</p>
                        </div>
                        <button onClick={() => navigate('/mis-solicitudes')} type="button" className="btn btn-link order-md-first">
                            Volver
                        </button>
                    </>
                );
    }
    
};

export default Bonuses;
