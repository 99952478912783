import { Children, FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { InputForm } from "../../RemoteJob/interfaces/interfaces";

const SelectInput:FC<InputForm> = ({formState, input, control, handleFunction, getValues}) => {
    useEffect(() => {
        if (handleFunction && input.defaultValue !== '') {
            handleFunction(input.key, input.defaultValue);
          }
      }, [input.defaultValue]);

    return (
        <span className="data-list-box__text ms-2">
          <Controller
            name={input.key}
            control={control}
            defaultValue={input.defaultValue || ''}
            render={({ field }) => (
              <>
                <select
                  {...field}
                  className="form-select"
                  value={getValues(input.key)}
                  disabled={input.readOnly}
                  onChange={(e) => {
                    if (handleFunction) {
                      handleFunction(input.key, e.target.value);
                    }
                    field.onChange(e);
                  }}
                >
                  <option value="">Seleccione</option>
                  {Children.toArray(input.options.map(opt => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  )))}
                </select>
                {formState.errors[input.key]?.message && (
                  <div className="invalid-feedback d-block">
                    {formState.errors[input.key]?.message}
                  </div>
                )}
              </>
            )}
          />
        </span>
      );
}

export default SelectInput;